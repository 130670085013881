<template>
  <v-row
    justify="center"
    align="center"
    class="flex-column"
  >
    <v-col>
      <v-row
        justify="center"
        class="flex-nowrap"
      >
        <v-col
          style="flex: 0 0 12%;max-width: 12%;"
        >
          <v-menu
            ref="monthPicker"
            v-model="showMonthPicker"
            :close-on-content-click="false"
            :return-value.sync="selectedDate"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormatted"
                outlined
                :prepend-icon="icons.mdiCalendar"
                readonly
                style="width: 120px;"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="selectedDate"
              type="month"
              no-title
              scrollable
              @input="$refs.monthPicker.save(selectedDate)"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="3"
        >
          <v-btn
            id="searchBtn"
            color="info"
            style="text-transform: none;margin-top: 8px;"
            @click="queryMemoCallback"
          >
            <div>Search</div>
          </v-btn>&nbsp;
          <v-btn
            color="success"
            style="text-transform: none;margin-top: 8px;"
            @click="saveMemoCallback"
          >
            <div>Save</div>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col>
      <ag-grid-vue
        class="ag-theme-alpine"
        dom-layout="autoHeight"
        :column-defs="columnDefs"
        :default-col-def="defaultColDef"
        :row-data="rowData"
        :get-row-class="getRowClass"
        :column-types="columnTypes"
        :stop-editing-when-cells-lose-focus="true"
        @first-data-rendered="onFirstDataRendered"
      >
      </ag-grid-vue>
    </v-col>
    <v-col style="display: flex;justify-content: left;">
      <!-- <v-row style="display: flex;justify-content: left;"> -->
      <span>【Notes】<br>
        &nbsp;&nbsp;When you CSV download , <label class="under">“<font color="red">,</font> ”(comma)</label> in comment data you input in Memo Box 1, 2 and 3 <label class="under">becomes “ “(space)</label>. <br>
        &nbsp;&nbsp;ex)<br>
        &nbsp;&nbsp;You input  : 「About 10<font color="red">,</font>000 customers ~」 <br>
        &nbsp;&nbsp;CSV download : 「About 10 000 customers ~」<br>
      </span>
      <!-- </v-row> -->
      <br>
      <!-- <v-row style="display: flex;justify-content: left;">
        <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Fixed Holiday
          </th>
          <th class="text-left">
            Special Day
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in desserts"
          :key="item.fix"
        >
          <td>{{ item.fix }}</td>
          <td class="text-left">
            {{ item.speical }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
      </v-row> -->
    </v-col>
    <v-col>
      <v-simple-table style="width:500px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Fixed Holiday
              </th>
              <th class="text-left">
                Special Day
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in desserts"
              :key="item.fix"
            >
              <td>{{ item.fix }}</td>
              <td class="text-left">
                {{ item.speical }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue'
import { ref } from '@vue/composition-api'
import { mdiCalendar } from '@mdi/js'
import moment from 'moment'

moment.locale('en')

export default {
  components: {
    AgGridVue,
  },

  props: {
    queryMemo: {
      type: Function,
      required: true,
    },
    saveMemo: {
      type: Function,
      required: true,
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.selectedDate)
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null

      return moment(date).format('MMM/yyyy')
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit()
    },
    helloChild() {
      console.log('hello from child')
      const searchBtn = document.getElementById('searchBtn')
      if (searchBtn != null) {
        setTimeout(() => {
          searchBtn.click()
        }, 500)
      }
    },
  },
  setup(props) {
    const showMonthPicker = ref(false)

    const selectedDate = ref(new Date().toISOString().substr(0, 7))

    const rowData = ref([])

    const defaultColDef = {
      initialWidth: 100,
      resizable: true,
    }

    const columnTypes = {
      editableColumn: { editable: true, cellEditor: 'agLargeTextCellEditor', cellEditorParams: { maxLength: '300', cols: '50', rows: '6' } },
    }

    const columnDefs = [
      { headerName: 'Date', field: 'date', minWidth: 100, cellStyle: { 'text-align': 'center' } },
      { headerName: 'Fixed Holiday', field: 'fixedHoliday' },
      { headerName: 'Special Day', field: 'specialHoliday' },
      { headerName: 'Memo 1', field: 'memo1', type: 'editableColumn' },
      { headerName: 'Memo 2', field: 'memo2', type: 'editableColumn' },
      { headerName: 'Memo 3', field: 'memo3', type: 'editableColumn' },
    ]

    const getRowClass = params => {
      if (params.data.date.indexOf('Sat') >= 0) {
        return ['memo-table', 'saturday']
      }
      if (params.data.date.indexOf('Sun') >= 0) {
        return ['memo-table', 'sunday']
      }

      return undefined
    }

    const queryMemoCallback = async () => {
      rowData.value = await props.queryMemo(new Date(selectedDate.value))
    }

    const saveMemoCallback = () => {
      props.saveMemo(rowData.value)
    }

    const desserts = [
      {
        fix: '1/Jan New Year Day',
        speical: '14/Feb Valentine',
      },
      {
        fix: '30/Apr Reunification Day',
        speical: "8/Mar International Women's Day",
      },
      {
        fix: "1/May Workers' Day",
        speical: "1/Jun International Children's Day",
      },
      {
        fix: '2/Sep National Day',
        speical: "20/Oct Vietnamese Women's Day",
      },
      {
        fix: '',
        speical: "20/Nov Teacher's Day",
      },
      {
        fix: '',
        speical: "24/Dec X'mas Eve",
      },
      {
        fix: '',
        speical: "25/Dec X'mas",
      },
      {
        fix: '',
        speical: '31/Dec New Year Eve',
      },
    ]

    return {
      desserts,
      showMonthPicker,
      selectedDate,
      defaultColDef,
      columnTypes,
      columnDefs,
      rowData,
      getRowClass,
      queryMemoCallback,
      saveMemoCallback,
      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-alpine.css';
@import '~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';

.memo-table {
  &.saturday {
    background-color: rgb(204, 255, 255) !important;
  }
  &.sunday {
    background-color: rgb(255, 204, 255) !important;
  }
}

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      font-family: 'Arial sans-serif',
      border-color: black,
      header-column-resize-handle-height: 100%,
      header-column-resize-handle-color: ag-derived(border-color),
      row-border-color: ag-derived(border-color),
      cell-horizontal-border: solid ag-derived(border-color),
      odd-row-background-color: null,
    )
  );
  .ag-ltr {
    .ag-header-cell:last-child > .ag-header-cell-resize {
      width: 0px;
    }
    .ag-row {
      .ag-cell:last-child {
        border-right: none;
      }
    }
    .ag-center-cols-container {
      .ag-row:last-child {
        border-bottom: none;
      }
    }
  }
}
.ag-header-cell-label {
  justify-content: center;
}
</style>
<style scoped>
.under {
  text-decoration: underline black;
}
</style>
